import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 19px;
  height: 14px;
  margin-right: 13px;

  @media (min-width: 768px) {
    width: 26px;
    height: 19px;
    margin-right: 18px;
  }
`;

const TickIcon = () => (
  <Svg viewBox="0 0 40 29">
    <path fill="#72c472" d="M0 13l4-4 10 8L36 0l4 3-26 26z" />
  </Svg>
);

export default TickIcon;
