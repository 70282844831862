import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  padding: 30px 0 17px;

  @media (min-width: 768px) {
    padding: 80px 0 55px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 18px;
`;

const Heading = styled.h2`
  font-size: 21px;
  line-height: 17px;
  font-weight: 500;
  color: #294f79;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 34px;
    margin-bottom: 37px;
  }
`;

const Mission = styled.p`
  font-size: 12px;
  line-height: 19px;
  margin-bottom: 35px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 24px;
    max-width: 580px;
    margin: 0 auto 70px;
  }
`;

const Steps = styled.div`
  @media (min-width: 1160px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1140px;
    margin: 0 auto;
  }
`;

const Step = styled.div`
  display: flex;
  margin-bottom: 35px;

  @media (min-width: 768px) {
    max-width: 580px;
    margin: 0 auto 50px;
  }

  @media (min-width: 1160px) {
    max-width: 520px;
    margin: 0 auto 90px;
  }
`;

const Image = styled.img`
  width: 56px;
  height: 56px;
  margin-right: 20px;

  @media (min-width: 768px) {
    width: 112px;
    height: 112px;
    margin-right: 42px;
  }
`;

const TextWrapper = styled.div`
  text-align: left;
`;

const StepHeading = styled.h3`
  font-weight: 300;
  color: #294f79;
  font-size: 17px;
  line-height: 15px;
  margin-bottom: 18px;

  @media (min-width: 768px) {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 14px;
  }
`;

const Text = styled.p`
  font-size: 13px;
  line-height: 19px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 30px;
  }
`;

const IncludedHeading = styled.h3`
  line-height: 17px;
  color: #294f79;
  font-weight: 400;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 34px;
  }
`;

const Lists = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    max-width: 790px;
    margin: 0 auto;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;

  li {
    font-size: 12px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
`;

export {
  Wrapper,
  Container,
  Heading,
  Mission,
  Steps,
  Step,
  Image,
  TextWrapper,
  StepHeading,
  Text,
  IncludedHeading,
  Lists,
  List
};
