import React, { Component } from 'react';
import { Wrapper, Container, Heading, Tagline, PullQuote, TextWrapper, Text } from './styles';

class About extends Component {
  render() {
    return (
      <Wrapper id="about-us">
        <Container>
          <Heading>About us</Heading>
          <Tagline>We are solely dedicated to preparing students for the SJT</Tagline>
          <PullQuote>
            We teach more students year on year than any other course, with verified scores consistently high above
            national average.
          </PullQuote>
          <TextWrapper>
            <div>
              <Text>
                At SJT Preparation we place a high value on the people we select to design, refine and deliver our
                course. That is why we only choose doctors who have recently faced preparation for the SJT. They have
                studied its structure and format in detail and compiled their experience and knowledge of preparation to
                produce a formula that maximises potential to score top marks in the test.
              </Text>
              <Text>
                All our doctors are now practising in highly competitive posts across the United Kingdom and are proud
                to be able to offer a comprehensive and up to date course on how to prepare thoroughly for the SJT.
              </Text>
            </div>
            <div>
              <Text>
                The intensive full day course will support you to equip yourself with the understanding and skills
                required to prepare for SJT success.
              </Text>
              <Text>
                The SJT score accounts for 50% of the mark used to rank and match final year medical students to the UK
                Foundation Programme. The weight it carries is impossible to ignore and preparing thoroughly is
                essential to avoid disappointment.
              </Text>
              <Text>
                SJTPreparation stresses that revising for the test is not possible, but that thorough, organised and
                focused preparation is the key to success.
              </Text>
            </div>
          </TextWrapper>
        </Container>
      </Wrapper>
    );
  }
}

export default About;
