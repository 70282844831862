import styled, {css} from 'styled-components';

const Wrapper = styled.div`
  background: url('static/at_a_glance.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 35px 0 13px;
  color: #fff;

  @media (min-width: 768px) {
    padding: 80px 0 55px;
  }

  ${({invert}) => invert && css`
    background: none;
    color: #444;
  `}
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 18px;
  max-width: 1140px;
`;

const Heading = styled.h2`
  font-size: 21px;
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 30px;
  }

  ${({ invert }) => invert && css`
    color: #294f79;
  `}
`;

const Sub = styled.div`
  max-width: 800px;
  margin: 0 auto 35px;

  h3 {
    margin: 0 10px 10px;
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;

const List = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;

    & > div {
      width: 47%;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Text = styled.p`
  font-size: 13px;
  line-height: 22px;
  text-align: left;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 40px;
  }
`;

export { Wrapper, Container, Heading, Sub, List, TextWrapper, Text };
