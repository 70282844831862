import React, { Component } from 'react';
import { Wrapper } from './styles';
import Hero from './Hero';
import Mission from './Mission';
import About from './About';
import MDU from './MDU';
import AtAGlance from '../CourseContent/AtAGlance';

class Home extends Component {
  render() {
    return (
      <Wrapper>
        <Hero />
        <AtAGlance {...{ invert: true }}/>
        <About />
        <Mission />
        <MDU />
      </Wrapper>
    );
  }
}

export default Home;
