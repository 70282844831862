import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  padding: 36px 0 15px;

  @media (min-width: 768px) {
    padding: 80px 0 55px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 18px;
  max-width: 1140px;

  @media (min-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Image = styled.img`
  width: 158px;
  height: 54px;
  margin-bottom: 35px;

  @media (min-width: 1000px) {
    width: 480px;
    height: 165px;
  }
`;

const TextWrapper = styled.div`
  @media (min-width: 1000px) {
    width: 45%;
  }
`;

const Text = styled.p`
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 30px;
  text-align: left;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 30px;
  }
`;

export { Wrapper, Container, Image, TextWrapper, Text };
