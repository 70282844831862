import React, { Component } from 'react';
import {
  Wrapper,
  Container,
  Heading,
  Mission,
  Steps,
  Step,
  Image,
  TextWrapper,
  StepHeading,
  Text,
  IncludedHeading,
  Lists,
  List
} from './styles';
import TickIcon from './TickIcon';

class MissionSection extends Component {
  render() {
    return (
      <Wrapper>
        <Container>
          <Heading>Our Mission</Heading>
          <Mission>
            We believe every medical student should enter their SJT fully prepared to maximise their potential and
            secure their first choice foundation programme.
          </Mission>
          <Steps>
            <Step>
              <Image src="static/strategy.svg" alt="Strategy Icon" />
              <TextWrapper>
                <StepHeading>1) Enrol</StepHeading>
                <Text>
                  Secure your place today - Book onto one of our {new Date().getFullYear()} UK wide SJT Preparation
                  courses. Some say failing to prepare is preparing to fail. We say that too.
                </Text>
              </TextWrapper>
            </Step>
            <Step>
              <Image src="static/prepare.svg" alt="Prepare Icon" />
              <TextWrapper>
                <StepHeading>2) Prepare</StepHeading>
                <Text>
                  Attend your local course. Understand the techniques our consultants used when preparing for the SJT to
                  learn how to prepare for success.
                </Text>
              </TextWrapper>
            </Step>
            <Step>
              <Image src="static/engage.svg" alt="Engage Icon" />
              <TextWrapper>
                <StepHeading>3) Engage</StepHeading>
                <Text>
                  You’ve been to our course. You’ve drawn on our expertise. You’ve sat our formative test. Now apply the
                  edge you have to enhance your score.
                </Text>
              </TextWrapper>
            </Step>
            <Step>
              <Image src="static/secure.svg" alt="Secure Icon" />
              <TextWrapper>
                <StepHeading>4) Secure</StepHeading>
                <Text>
                  Look forward to FPAS allocation results day March {new Date().getFullYear() + 1}. Thanks to planning
                  ahead, you have increased your potential to secure your first place Unit of Application.
                </Text>
              </TextWrapper>
            </Step>
          </Steps>
          <IncludedHeading>What's included?</IncludedHeading>
          <Lists>
            <List>
              <li>
                <TickIcon />
                Full day course
              </li>
              <li>
                <TickIcon />
                All question types explained
              </li>
              <li>
                <TickIcon />
                From doctors who have prepared for the SJT
              </li>
              <li>
                <TickIcon />
                Breakdown of exam structure
              </li>
            </List>
            <List>
              <li>
                <TickIcon />
                Detailed coverage of all 5 SJT domains
              </li>
              <li>
                <TickIcon />
                Realistic online mock test
              </li>
              <li>
                <TickIcon />
                New testing process explained
              </li>
              <li>
                <TickIcon />
                Dedicated eBook with 100 SJT scenarios
              </li>
            </List>
          </Lists>
        </Container>
      </Wrapper>
    );
  }
}

export default MissionSection;
