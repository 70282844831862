import React, { Component } from 'react';
import Home from '../components/Home';

class Index extends Component {
  render() {
    return <Home {...this.props} />;
  }
}

export default Index;
