import React, { Component } from 'react';
import { Wrapper, Container, Image, TextWrapper, Text } from './styles';

class MDU extends Component {
  render() {
    return (
      <Wrapper>
        <Container>
          <Image src="static/mdu_logo.png" alt="MDU Logo" />
          <TextWrapper>
            <Text>
              As the UK’s most popular SJT support provider we’re proud to be associated with the UK’s leading medical
              defence organisation, the Medical Defence Union (MDU).{' '}
            </Text>
            <Text>
              The MDU is a not-for-profit organisation led by doctors with real-life experience of the pressures and
              challenges faced in practice.{' '}
            </Text>
            <Text>
              We offer members guidance, support and defence for medico-legal issues, complaints and claims. Our members
              now get the best rates on SJT Preparation courses too!
            </Text>
          </TextWrapper>
        </Container>
      </Wrapper>
    );
  }
}

export default MDU;
