import styled from 'styled-components';

const Wrapper = styled.div`
  background: url('static/hero_image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  color: #fff;

  @media (min-width: 768px) {
    padding-top: 220px;
  }
`;

const Heading = styled.h1`
  text-transform: uppercase;
  font-size: 29px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 18px;

  @media (min-width: 768px) {
    font-size: 52px;
    line-height: 53px;
    margin-bottom: 30px;
  }
`;

const Tagline = styled.p`
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 35px;

  @media (min-width: 768px) {
    font-size: 28px;
    margin-bottom: 64px;
  }
`;

const Button = styled.button`
  text-transform: uppercase;
  background: #14b34f;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 19px;
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.2);
  padding: 19px 60px;
  margin-bottom: 50px;
  cursor: pointer;

  @media (min-width: 768px) {
    font-size: 24px;
    padding: 25px 73px;
    border-radius: 6px;
    margin-bottom: 170px;
  }
`;

const Footer = styled.div`
  background: rgba(0, 0, 0, 0.15);
  padding: 25px 0 10px;

  @media (min-width: 768px) {
    padding: 33px 16px;
  }
`;

const Partnership = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;

  p {
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`;

const Image = styled.img`
  width: 112px;
  height: 39px;
  margin-left: 15px;

  @media (min-width: 768px) {
    width: 155px;
    height: 54px;
    margin-left: 20px;
  }
`;

const Features = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    max-width: 1140px;
    margin: 0 auto;
  }
`;

const Feature = styled.p`
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 20px;
    margin-bottom: 0;
  }
`;

const Limited = styled.p`
  color: #ffa700;
  margin-bottom: 20px;
`

export { Wrapper, Heading, Tagline, Button, Footer, Partnership, Image, Features, Feature, Limited };
