import styled from 'styled-components';

const Wrapper = styled.div`
  background: url('static/about_us.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 35px 0 13px;
  color: #fff;

  @media (min-width: 768px) {
    padding: 80px 0 55px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 18px;
  max-width: 1140px;
`;

const Heading = styled.h2`
  font-size: 21px;
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 34px;
    margin-bottom: 30px;
  }
`;

const Tagline = styled.p`
  font-size: 12px;
  line-height: 19px;
  opacity: 0.6;
  max-width: 210px;
  margin: 0 auto 32px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 24px;
    max-width: none;
    margin-bottom: 70px;
  }
`;

const PullQuote = styled.p`
  font-family: 'Ubuntu', sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  font-style: italic;
  text-align: left;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 64px;
    max-width: 990px;
    margin: 0 auto 80px;
  }
`;

const TextWrapper = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;

    div {
      width: 45%;
    }
  }
`;

const Text = styled.p`
  font-size: 13px;
  line-height: 22px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 30px;
  }
`;

export { Wrapper, Container, Heading, Tagline, PullQuote, TextWrapper, Text };
