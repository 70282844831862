import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 18px;
`;

export { Wrapper, Container };
