import React, { Component } from 'react';
import { Wrapper, Container, Heading, Sub, List, TextWrapper, Text } from './styles';
import GreenCircleTickIcon from './GreenCircleTickIcon';

class AtAGlance extends Component {
  render() {
    const {invert} = this.props;

    return (
      <Wrapper {...{invert}}>
        <Container>
          <Heading {...{ invert }}>Complete preparation platform</Heading>
          <Sub>
            <h3>Most popular SJT course</h3>
            <h3>Realistic online mock</h3>
            <h3>Comprehensive question library</h3>
          </Sub>
          <List>
            <div>
              <TextWrapper>
                <GreenCircleTickIcon />
                <Text>
                  Highly regarded preparation method consistently peer reviewed and updated by a growing cohort of doctors who have sat the SJT
                </Text>
              </TextWrapper>
              <TextWrapper>
                <GreenCircleTickIcon />
                <Text>
                  Fully updated content for {new Date().getFullYear()} to cover all question types in line with UKFPO
                  guidance and important information regarding the new testing process
                </Text>
              </TextWrapper>
              <TextWrapper>
                <GreenCircleTickIcon />
                <Text>
                  Realistic online mock exam – Test yourself with original questions under timed exam conditions to familiarise yourself with the format and gauge your current performance level.
                </Text>
              </TextWrapper>
              <TextWrapper>
                <GreenCircleTickIcon />
                <Text>Analysis of SJT domains and scenarios key to understanding the test</Text>
              </TextWrapper>
            </div>
            <div>
              <TextWrapper>
                <GreenCircleTickIcon />
                <Text>
                  Explanation by FY1s and FY2s of how SJT scenarios mirror real experiences and how you can apply this
                  to the test
                </Text>
              </TextWrapper>
              <TextWrapper>
                <GreenCircleTickIcon />
                <Text>Discussion of core concepts drawn from GMC and UKFPO guidance and applicability to the SJT</Text>
              </TextWrapper>
              <TextWrapper>
                <GreenCircleTickIcon />
                <Text>Open Q & A throughout to address key issues</Text>
              </TextWrapper>
              <TextWrapper>
                <GreenCircleTickIcon />
                <Text>
                  Exclusive online resource with 100 original SJT scenarios for course attendees – test yourself again
                  with a further 100 questions and worked answers
                </Text>
              </TextWrapper>
              <TextWrapper>
                <GreenCircleTickIcon />
                <Text>Direct email support with a consultant at any time</Text>
              </TextWrapper>
            </div>
          </List>
        </Container>
      </Wrapper>
    );
  }
}

export default AtAGlance;
