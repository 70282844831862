import React, { Component } from 'react';
import Link from 'next/link';
import { Wrapper, Heading, Tagline, Button, Footer, Partnership, Image, Features, Feature, Limited } from './styles';

class Hero extends Component {
  render() {
    return (
      <Wrapper>
        <Heading>SJT Preparation</Heading>
        <Tagline>The most popular SJT course | Complete preparation platform</Tagline>
        <Link prefetch href="/book">
          <a>
            <Button>Book Now</Button>
          </a>
        </Link>
        <Footer>
          <Partnership>
            <p>In partnership with</p>
            <Image src="static/mdu_white.png" alt="MDU Logo" />
          </Partnership>
          <Features>
            <Feature>MDU discount available</Feature>
            <Feature>Most popular course in {new Date().getFullYear() - 1}</Feature>
            <Feature>99% satisfaction rate</Feature>
            <Feature>New testing process explained</Feature>
          </Features>
        </Footer>
      </Wrapper>
    );
  }
}

export default Hero;
