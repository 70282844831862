import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 19px;
  height: 19px;
  flex: 0 0 19px;
  margin-right: 9px;
  margin-top: 3px;

  @media (min-width: 768px) {
    width: 26px;
    height: 26px;
    flex: 0 0 26px;
    margin-right: 17px;
  }
`;

const Path = styled.path`
  fill: none;
  stroke: #4eb18a;
  stroke-width: 2;
  stroke-linecap:
  stroke-miterlimit: 10;
`;

const GreenCircleTickIcon = () => (
  <Svg viewBox="0 0 24 24">
    <g>
      <Path d="M6 12l4 4 8-8" />
      <Path as="circle" cx={12} cy={12} r={11} />
    </g>
  </Svg>
);

export default GreenCircleTickIcon;
